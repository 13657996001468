<template>
  <div class="row">
    <div class="col ps-3">
      <h2 class="text-primary"><router-link :to="{name: 'Stock', params: {id: $route.params.id}}"><i class="bi bi-arrow-left"></i></router-link> {{name}} <span class="small"><i class="bi bi-geo-alt"></i> {{location}}</span></h2>
    </div>
  </div>

  <div class="row">
    <div class="col col-lg-6 pb-3">
      <div class="card p-2 h-100">
        <TemperatureChart :freezer="$route.params.id || defaultId" :canvas-id="'tempchart'"/>
      </div>
    </div>
    <div class="col col-lg-6 pb-3">
      <div class="card p-2 h-100">
        <StockChart :freezer="$route.params.id || defaultId" :canvas-id="'stockchart'"/>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col col-lg-6 pb-3">
      <div class="card p-2 h-100">
        <SalesChart :freezer="$route.params.id || defaultId" :canvas-id="'saleschart'"/>
      </div>
    </div>

    <div class="col col-lg-6 pb-3">
      <div class="card p-2 h-100">
        <iframe v-if="osmUrl" width="auto" height="100%" frameborder="0" scrolling="no" marginheight="0"
                marginwidth="0"
                :src="osmUrl"
                style=""></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import TemperatureChart from "@/components/TemperatureChart";
import StockChart from "@/components/StockChart";
import SalesChart from "@/components/SalesChart";
import {FreezerUtil} from "@/demo/FreezerData";
import {GeoUtl} from "@/utils/GeoUtl";

export default {
  name: "Chart",
  components: {SalesChart, TemperatureChart, StockChart},
  data() {
    return {
      freezerList: FreezerUtil.data,
    }
  },
  computed: {
    osmUrl() {
      if(this.$route.params.id in this.freezerList) {
        return GeoUtl.createOsmUrl(this.freezerList[this.$route.params.id].gps)
      }
      return null;
    },
    name: function () { return FreezerUtil.name(this.$route.params.id) },
    location: function () { return FreezerUtil.location(this.$route.params.id) },
  }
}
</script>

<style scoped lang="scss">

@import "../assets/styles/variables";
@import "~bootstrap";

.card {
  background-color: $light-blue;
  border: solid 8px $blue;
  box-shadow: none;
}
.small {
  font-size: 0.65em;
}

</style>