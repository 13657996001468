<template>

  <div v-for="freezer in freezerList" :key="freezer.id" class="row">

    <router-link :to="{name: 'Stock', params: { id: freezer.id } }">
      <div class="col pb-3">
        <div class="card p-2 h-100">
          <div class="row">
            <div class="col col-lg-2 col-md-4 text-center">
              <img class="w-100" :src="src">
            </div>
            <div class="col col-lg-7 col-md-6 text-center text-primary m-auto">
              <h1>{{ freezer.name }}</h1>
              <h3><i class="bi bi-geo-alt"></i> {{ freezer.location }}</h3>
            </div>
            <div class="col col-lg-3 d-lg-block d-none" >
              <iframe v-if="osmUrl(freezer.id)" width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0"
                      marginwidth="0"
                      :src="osmUrl(freezer.id)"
                      style=""></iframe>
            </div>
          </div>
        </div>
      </div>
    </router-link>

  </div>
</template>

<script>
import {FreezerUtil} from "@/demo/FreezerData";
import {GeoUtl} from "@/utils/GeoUtl";

export default {
  name: "Home",
  data() {
    return {
      freezerList: FreezerUtil.data,
      src: require('@/assets/img/smartetruhe-small.png'),
    }
  },
  methods: {
    osmUrl: function (id) {
      if(id in this.freezerList) {
        return GeoUtl.createOsmUrl(this.freezerList[id].gps);
      }
      return null;
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables";
@import "~bootstrap";

.card {
  background-color: $light-blue;
  border: solid 8px $blue;
  box-shadow: none;
}

a {
  text-decoration: none;
}

</style>