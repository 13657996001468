<template>
  <div class="fullscreen">
    <!-- TODO  !embed-->
    <nav v-if="false"
            class="navbar navbar-dark navbar-expand-lg bg-primary">
      <div class="container-fluid">
        <router-link to="/" class="navbar-brand ps-5"><img class="florida-logo pt-2" src="@/assets/logo.png">
        </router-link>
      </div>
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="navbar-brand">
            <div class="navbar-title">DIE SMARTE TRUHE</div>
          </li>
        </ul>
      </div>
    </nav>
    <div class="container py-4">
      <router-view/>
    </div>
  </div>
  <!-- TODO  !embed-->
  <nav v-if="false" class="navbar navbar-dark navbar-expand-lg bg-primary">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item ps-3">
          <router-link class="nav-link" active-class="active" to="/freezer">Truhen</router-link>
        </li>
      </ul>
      <ul class="navbar-nav">
        <li class="nav-item pe-3">
          <router-link class="nav-link" active-class="active" to="/about">Impressum</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
  export default {
    computed: {
      embed: function() {
        return this.$route.query.embed === 'true';
      }
    }
  }
</script>


<style lang="scss">
@import "./assets/styles/variables";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./assets/styles/custom.scss";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";

/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.fullscreen {
  min-height: 100vh;
}

.bg-brown {
  background-color: $light-brown;
}

.florida-logo {
  width: 400px;
}

.navbar-title {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.2;
  padding-right: 3rem;
}



</style>
