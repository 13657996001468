// Api Wrapper using fetch api
// see: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
class ApiProvider {

    async get(url, searchParams = {}) {
        const search = new URLSearchParams(searchParams).toString()
        const response = await fetch(url + '?' + search, {
            method: 'GET',
            cache: 'no-cache',
            mode: 'same-origin',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json'
            },
            redirect: 'error',
            referrerPolicy: 'no-referrer'
        });
        if(response.ok) {
            return response.json()
        }else{
            throw response;
        }
    }

    async post(url, body, searchParams = {}, header = {}) {
        const search = new URLSearchParams(searchParams).toString()
        const response = await fetch(url + '?' + search, {
            method: 'POST',
            cache: 'no-cache',
            mode: 'same-origin',
            credentials: 'same-origin',
            headers: {...{'Accept': 'application/json', 'Content-Type': 'application/json' }, ...header},
            redirect: 'error',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(body)
        });
        if(response.ok) {
            return response.json()
        }else{
            throw response;
        }

    }

}

const api = new ApiProvider()

export default api


