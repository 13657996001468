<template>
  <div class="d-flex justify-content-center">
      <nav>
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link" href="#" :class="{ disabled: page.first } " @click="selectPage(0)">&laquo;&laquo;</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#" :class="{ disabled: page.first } " @click="selectPage(page.number - 1)">&laquo;</a>
          </li>
          <li class="page-item disabled">
            <span class="page-link text-dark">Seite {{ page.number + 1 }} von {{ page.totalPages }}</span></li>
          <li class="page-item">
            <a class="page-link" href="#" :class="{ disabled: page.last } "
               @click="selectPage(page.number + 1)">&raquo;</a></li>
          <li class="page-item">
            <a class="page-link" href="#" :class="{ disabled: page.last } " @click="selectPage(page.totalPages - 1)">&raquo;&raquo;</a>
          </li>
        </ul>
      </nav>
  </div>
</template>

<script>
export default {
  name: "Pager",
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  methods: {
    selectPage(pageIndex) {
      this.$emit('select-page', pageIndex)
    }
  },
  created() {
    console.log(this.page)
  }

}
</script>

<style scoped>
a.disabled {
  pointer-events: none;
  color: #767676;
}
</style>