<template>
  <h3>Record: {{ $route.params.id }}</h3>
  <div class="loading" v-if="loading">Loading....</div>
  <div class="error" v-if="error">Fehler beim Laden: {{ err }}</div>
  <div class="content" v-if="record">
    <table class="table table-sm table-hover">
      <thead>
      <tr>
        <th>Truhe</th>
        <th>Record</th>
        <th>Datum</th>
        <th>Erfasste Tags</th>
        <th>Temperatur</th>
        <th>GPS</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>
          <router-link :to="{name: 'Freezer', params: { id: record.freezerId }}">{{ record.freezerId }}</router-link>
        </td>
        <td>{{ record.id }}</td>
        <td>{{ $fmt.dateAndTime(record.timestamp) }}</td>
        <td>{{ record.tags.length }}</td>
        <td>{{ $fmt.number(record.temperature) }} °C</td>
        <td> {{ $fmt.gps(record.latitude) }}°, {{ $fmt.gps(record.longitude) }}°</td>
      </tr>
      </tbody>
    </table>

    <h4>Erfasste Tags:</h4>

    <div class="container">

      <div class="row row-cols-md-1 row-cols-lg-2 row-cols-xl-2">
        <div class="col p-1" v-for="(tag, index) in record.tags" :key="index">
          <div class="row g-3">
            <div class="col-auto">
              <span class="form-control-plaintext font-monospace">{{tag.epc}}</span>
              <input type="hidden" class="form-control-plaintext" :id="tag.epc" :value="tag.epc">
            </div>
            <div class="col-auto">
              <select class="form-control form-select" v-model="tag.articleNumber">
                <option v-for="option in options" :value="option.value" :key="option.value">{{ option.label }}</option>
              </select>
            </div>
            <div class="col-auto">
              <span class="form-control-plaintext font-monospace">rssi: {{tag.rssi}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row pt-2 g-3">
        <div class="col-auto">
          <select class="form-control form-select" v-model="changeAll">
            <option v-for="option in options" :value="option.value" :key="option.value">{{ option.label }}</option>
          </select>
        </div>
        <div class="col-auto">
          <button class="btn btn-primary mb-3" @click="setAllItems">Alle Sorten ändern</button>
        </div>
      </div>

      <div class="row pt-2 g-3">
        <div class="col-auto">
          <label for="inputToken" class="visually-hidden">Token</label>
          <input v-model="token" type="password" class="form-control" id="inputToken" placeholder="Token">
        </div>
        <div class="col-auto">
          <button class="btn btn-primary mb-3" @click="postData">Speichern</button>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import api from "@/api/ApiProvider";

export default {
  name: "Record",
  data() {
    return {
      loading: false,
      record: null,
      error: null,
      options: [
        {value: null, label: "unbekannt"},
        {value: "0501", label: "VANILLE"},
        {value: "0583", label: "DOUBLE CHOCOLATE"},
        {value: "0582", label: "SALTED CARAMEL"},
        {value: "0552", label: "MANGO"},
        {value: "0509", label: "JOGHURT"},
      ],
      token: '',
      changeAll: null,
    }
  },
  created() {
    this.$watch(
        () => this.$route.params.id,
        id => {
          if (id) this.fetchData();
        },
        {immediate: true})
  },
  methods: {
    fetchData() {
      api.get('/api/record/' + this.$route.params.id)
          .then(rec => {
            this.loading = false;
            this.record = rec;
            this.error = null;
          })
          .catch(err => {
            this.loading = false;
            this.error = err;
            this.record = null;
          })
    },
    postData() {
      api.post('/api/admin/tagmapping', this.record.tags, {}, {'x-token': this.token})
          .then(res => {
            console.log('post result:', res);
            alert('Änderungen gespeichert!');
          })
          .catch(err => {
            console.log('error', err);
            alert('Fehler beim speichern');
          })
      //console.log('tags', this.record.tags);
    },
    setAllItems() {
      console.log(this.changeAll);
      this.record.tags.forEach(rec => rec.articleNumber = this.changeAll);
    }
  }

}
</script>

<style scoped>
</style>
