<template>
  <div class="text-center text-primary">
    <span class="px-2 fw-bold">Truhenfüllstand</span>
  </div>
  <div class="position-relative">
    <canvas :id="canvasId" :class="{ invisible: !hasData }"></canvas>
    <div class="text-center position-absolute top-50 w-100 text-primary" :class="{ invisible: (hasData || loading) }">Keine Daten im Zeitraum vorhanden</div>
    <div class="text-center position-absolute top-50 w-100 text-primary" v-if="loading"><i class="bi bi-arrow-repeat rotate"></i></div>
  </div>
  <div class="text-center text-primary">
    <a href="#" @click.prevent="prevDay"><i class="bi bi-arrow-left-circle"></i></a> <span class="px-2">{{ $fmt.dayMonth(fromDate) }} - {{ $fmt.dayMonth(toDate) }}</span> <a href="#" @click.prevent="nextDay"><i class="bi bi-arrow-right-circle"></i></a>
  </div>
</template>

<script>
import api from "@/api/ApiProvider";
import Chart from "chart.js/auto";

export default {
  name: "StockChart",
  props: {
    freezer: {
      type: String,
      required: true,
    },
    canvasId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      loading: false,
      hasData: false,
      curDate: new Date(),
    }
  },
  computed: {
    fromDate() {
      const from = new Date(this.curDate)
      from.setDate(from.getDate() - 14)
      return from;
    },
    toDate() {
      return this.curDate;
    }
  },
  created() {
    this.chartConfig = {};
    this.chart = null;
    this.$watch(() => this.curDate,() => this.loadChart(), {immediate: true})
  },
  beforeUnmount() {

    if(this.chart !== null) {
      this.chart.destroy();
    }
    const ctx = document.getElementById(this.canvasId);
    ctx.remove();
  },
  methods: {
    loadChart() {
      this.loading = true;
      const url = '/api/freezer/' + this.freezer + '/stock';
      api.get(url, {date: this.curDate.getFullYear() + "-" + String(this.curDate.getMonth()+1).padStart(2, "0") + "-" + String(this.curDate.getDate()).padStart(2, "0")})
          .then(res => {
            this.hasData = Object.entries(res).length !== 0;
            const timeLabels = Object.keys(res)
            const temperatures = Object.values(res);
            this.chartConfig = {
              type: "line",
              data: {
                labels: timeLabels,
                datasets: [{
                  label: 'Bestand (Stk)',
                  data: temperatures,
                  fill: false,
                  //borderColor: 'rgb(2,160,227)',
                  borderColor: 'rgb(137,190,42)',
                  tension: 0.1
                }]
              },
              options: {
                plugins: {
                  legend: false
                }
              }
            };
            if(this.chart != null) {
              this.chart.data.labels = timeLabels;
              this.chart.data.datasets[0].data = temperatures;
              this.chart.update();
            }else{
              const ctx = document.getElementById(this.canvasId);
              this.chart = new Chart(ctx, this.chartConfig)
            }

          }).catch(err => {
        this.hasData = false;
        console.error("error :( todo show error", err)
      }).finally(() => {
        this.loading = false;
      })
    },
    nextDay() {
      this.curDate.setDate(this.curDate.getDate() + 14);
      //set new reference to trigger watcher
      this.curDate = new Date(this.curDate)
      return false;
    },
    prevDay() {
      this.curDate.setDate(this.curDate.getDate() - 14);
      //set new reference to trigger watcher
      this.curDate = new Date(this.curDate)
      return false;
    }
  }
}
</script>

<style scoped>

.rotate {
  -webkit-animation: infiniteRotate 0.5s linear infinite; /* Safari */
  animation: infiniteRotate 0.5s linear infinite;
}

</style>