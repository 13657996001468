export const FreezerUtil = {
    data: {
        '000000000000000000000002': {
            id: '000000000000000000000002',
            name: 'EDEKA Görse & Meichsner',
            location: 'Eichborndamm 77-85, 13403 Berlin',
            gps: {
                lat: 52.57585, //0,
                lng: 13.3142, //0,
            }
        },
        '000000000000000000000003': {
            id: '000000000000000000000003',
            name: 'Nahkauf Oelmann',
            location: 'Adamstr. 6, 13595 Berlin',
            gps: {
                lat:  52.5211415,
                lng: 13.1957983
            }
        },
        '000000000000000000000004': {
            id: '000000000000000000000004',
            name: 'EDEKA Wendenschloss',
            location: 'Wendenschloßstr. 324, 12557 Berlin',
            gps: {
                lat: 52.4283689,
                lng: 13.5844098
            }
        },
        '000000000000000000000005': {
            id: '000000000000000000000005',
            name: 'EDEKA Lechertshuber & Wimmer',
            location: 'Bürgermeister-Hess-Straße 8, 84453 Mühldorf am Inn',
            gps: {
                lat: 48.25231,
                lng: 12.53129
            }
        },
        '000000000000000000000006': {
            id: '000000000000000000000006',
            name: 'Kaufland Magdeburg-Stadtfeld O',
            location: 'Zum Handelshof 1, 39108 Magdeburg',
            gps: {
                lat: 52.12060,
                lng: 11.61265
            }
        },
    },

    name(id) {
        if (id in this.data) {
            return this.data[id].name;
        }
        return "Truhe: " + id;
    },
    location(id) {
        if (id in this.data) {
            return this.data[id].location;
        }
        return "Adresse unbekannt";
    }


};

