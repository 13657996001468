export default class FormatUtil {

    constructor(locale) {
        this.nf = new Intl.NumberFormat(locale, { maximumFractionDigits: 2, minimumFractionDigits: 0 });
        this.df = new Intl.DateTimeFormat(locale, {dateStyle: 'long', timeZone: 'Europe/Berlin'});
        this.tf = new Intl.DateTimeFormat(locale, {hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: 'Europe/Berlin'});
        this.dmf = new Intl.DateTimeFormat(locale, {day: 'numeric', month: '2-digit', timeZone: 'Europe/Berlin'});
        this.dtf = new Intl.DateTimeFormat(locale, {dateStyle: 'long', timeStyle: 'medium', timeZone: 'Europe/Berlin'});
        this.gpsf = new Intl.NumberFormat(locale, { maximumFractionDigits: 5, minimumFractionDigits: 5 });

    }

    number(number) {
        if(typeof number === 'undefined'){
            return ''
        }
        if(!(number instanceof Number)){
            try {
                number = Number.parseFloat(number)
            }catch (e) {
                return '';
            }
        }
        return this.nf.format(number)
    }

    gps(coordinate) {
        if(typeof coordinate === 'undefined'){
            return ''
        }
        if(!(coordinate instanceof Number)){
            try {
                coordinate = Number.parseFloat(coordinate)
            }catch (e) {
                return '';
            }
        }
        return this.gpsf.format(coordinate)
    }

    dateAndTime(date) {
        if(typeof date === 'undefined'){
            return ''
        }
        if(!(date instanceof Date)) {
            try{
                date = Date.parse(date)
            }catch (e) {
                return ''
            }

        }
        return this.dtf.format(date)
    }

    date(date) {
        if(typeof date === 'undefined'){
            return ''
        }
        if(!(date instanceof Date)) {
            try{
                date = Date.parse(date)
            }catch (e) {
                return ''
            }

        }
        return this.df.format(date)
    }
    dayMonth(date) {
        if(typeof date === 'undefined'){
            return ''
        }
        if(!(date instanceof Date)) {
            try{
                date = Date.parse(date)
            }catch (e) {
                return ''
            }

        }
        return this.dmf.format(date)
    }

    time(date) {
        if(typeof date === 'undefined'){
            return ''
        }
        if(!(date instanceof Date)) {
            try{
                date = Date.parse(date)
            }catch (e) {
                return ''
            }

        }
        return this.tf.format(date)
    }

}
