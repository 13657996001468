<template>
  <h2 class="">Truhenübersicht</h2>
  <div v-if="loading" class="loading">Daten werden geladen</div>
  <div v-if="error" class="error">{{ error }}</div>
  <div v-if="page">
    <p>{{ page.totalElements }} Truhen gefunden.</p>
    <div class="table-responsive-md">
      <table class="table table-sm table-hover" v-if="page.totalElements > 0">
        <thead>
        <tr>
          <th>Truhe</th>
          <th>Datum</th>
          <th>Erfasste Tags</th>
          <th>Temperatur</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="frz in page.content" :key="frz.id">
          <td>
            <router-link :to="{name: 'Freezer', params: {id: frz.id}}">{{ frz.id }}</router-link> &nbsp;
            <router-link :to="{name: 'Home', params: {id: frz.id}}">akt. Bestand</router-link>
          </td>
          <td>{{ $fmt.dateAndTime(frz.lastRecord) }}</td>
          <td>{{ frz.tagCount }}</td>
          <td>{{ $fmt.number(frz.temp) }} °C</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="nav-pager">
      <pager @select-page="fetchData" :page="page"/>
    </div>
  </div>
</template>

<script>
import api from '@/api/ApiProvider'
import Pager from "@/components/Pager";

export default {
  name: "FreezerList",
  data() {
    return {
      loading: false,
      page: null,
      error: null
    }
  },
  created() {
    this.fetchData(0);
  },
  methods: {
    fetchData(pageIndex) {
      this.loading = true;
      api.get('/api/freezer', {page: pageIndex, sort: 'lastRecord,desc'})
          .then(page => {
            console.log(page);
            this.loading = false;
            this.page = page;
            this.error = null;
          })
          .catch(err => {
            this.loading = false;
            this.page = null;
            this.error = err;
          })
    },
    fmtTemp(temp) {
      return temp + '°C'
    },
  },
  components: {
    Pager
  }
}
</script>

<style scoped>

</style>