<template>

  <div class="row">
    <div class="col ps-3">
      <h2 class="text-primary"><router-link :to="{name: 'Home', params: {id: $route.params.id}}"><i class="bi bi-arrow-left"></i></router-link> {{name}} <span class="small"><i class="bi bi-geo-alt"></i> {{location}}</span></h2>
    </div>
  </div>

  <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3">

    <div class="col pb-2">
      <div class="card p-3 h-100">
        <div class="d-flex m-auto">
          <div class="clear text-center text-primary">
            <div class="text-success text-xl">
              <transition name="fade" mode="out-in">
                <span :key="total">{{ total }} Becher</span>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="col pb-2">
      <div class="card p-3 h-100">
        <div class="d-flex m-auto">
          <div class="clear text-center text-primary">
            <div class="text-xl">
              <transition name="fade" mode="out-in">
                <span :key="temp">{{ $fmt.number(temp) }}° <i class="bi bi-thermometer-snow"></i></span>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col col-md-12 pb-2">
      <div class="card p-3 h-100">
        <iframe v-if="osmUrl" width="auto" height="250" frameborder="0" scrolling="no" marginheight="0"
                marginwidth="0"
                :src="osmUrl"
                style=""></iframe>
        <!--
        <br/><small><a href="https://www.openstreetmap.org/?mlat=52.53347&amp;mlon=13.12613#map=17/52.53347/13.12613">Größere
        Karte anzeigen &amp;</a></small>
        -->
      </div>
    </div>

  </div>

  <!-- TODO extract to component -->
  <div class="row row-cols-2 row-cols-lg-3 row-cols-xl-5 pt-2 justify-content-around">
    <div v-for="(article, propertyName) in articles" :key="propertyName"
         class="col pb-2">

      <div class="card text-center p-3 h-100">
        <div class="pb-3 d-flex justify-content-center">
          <img class="product-img" :alt="article.title" :src="article.src" style="width: 90%">
        </div>
        <span class="product-title text-primary">{{ article.title }}</span>
        <div class="amount-wrapper">
          <transition name="fade" mode="out-in">
            <div class="text-xl" :key="article.amount"
                 :class="article.amount > 4 ? 'text-success' : article.amount > 0 ? 'text-warning': 'text-danger'">
              <div class="position-absolute bottom-0 mb-3 start-50 translate-middle-x">{{ article.amount }}</div>
            </div>
          </transition>
        </div>
      </div>

    </div>

  </div>
  <div class="row">
    <div class="col p-3 col-auto me-auto">
      <div class="h4 text-primary"><i class="ps-2 bi bi-calendar3"></i> {{ $fmt.date(time) }} <i
          class="ps-2 bi bi-clock"></i> {{ $fmt.time(time) }}
      </div>
    </div>
    <div class="col pt-3 col-auto" v-if="recordId">
      <router-link :to="{name: 'Chart', params: {id: $route.params.id || defaultId}}"><i
          class="h4 text-primary bi bi-graph-up"></i></router-link>
    </div>
    <div class="col pt-3 col-auto" v-if="recordId && false">
      <router-link :to="{name: 'Record', params: {id: recordId}}"><i class="h4 text-primary bi bi-sliders"></i>
      </router-link>
    </div>
  </div>

</template>

<script>
import api from "@/api/ApiProvider";
import {FreezerUtil} from "@/demo/FreezerData";
import {GeoUtl} from "@/utils/GeoUtl";

export default {
  name: 'Stock',
  data() {
    return {
      defaultId: '000000000000000000000002',
      //id: '60a37e89787f36600a83746c',
      recordId: null,
      total: 0,
      time: "2021-05-20 11:04:46",
      temp: 0,
      gps: {
        // FIXME /13.3142
        lat: 52.57585, //0,
        lng: 13.3142, //0,
      },
      osmUrl: null,
      articles: {
        '0501': {
          title: 'VANILLE',
          src: require('@/assets/img/FLORIDA-EIS-VANILLE-500ML-NP.png'),
          amount: 0,
        },
        '0583': {
          title: 'DOUBLE CHOCOLATE',
          src: require('@/assets/img/FLORIDA-EIS-DOUBLE-CHOCOLATE-500ML-NP.png'),
          amount: 0,
        },
        '0582': {
          title: 'SALTED CARAMEL',
          src: require('@/assets/img/FLORIDA-EIS-SALTED-CARAMEL-500ML-NP.png'),
          amount: 0,
        },
        '0552': {
          title: 'MANGO',
          src: require('@/assets/img/FLORIDA-EIS-MANGO-500ML-NP.png'),
          amount: 0,
        },
        '0509': {
          title: 'JOGHURT',
          src: require('@/assets/img/FLORIDA-EIS-JOGHURT-500ML-NP.png'),
          amount: 0,
        },
        '0512': {
          title: 'COOKIE',
          src: require('@/assets/img/FLORIDA-EIS-COOKIE-500ML.png'),
          amount: 0,
        },
        '0516': {
          title: 'NOUGAT',
          src: require('@/assets/img/FLORIDA-EIS-NOUGAT-500ML.png'),
          amount: 0,
        },
        '0522': {
          title: 'MANDELKROKANT',
          src: require('@/assets/img/FLORIDA-EIS-MANDELKROKANT-500ML.png'),
          amount: 0,
        },
        '0503': {
          title: 'HASELNUSS',
          src: require('@/assets/img/FLORIDA-EIS-HASELNUSS-500ML.png'),
          amount: 0,
        },
        '0523': {
          title: 'SPEKULATIUS',
          src: require('@/assets/img/FLORIDA-EIS-SPEKULATIUS-500ML.png'),
          amount: 0,
        },
        '0374': {
          title: 'MANDELKROKANT',
          src: require('@/assets/img/FLORIDA-EIS-MANDELKROKANT-150ML.png'),
          amount: 0,
        },
        '0331': {
          title: 'VANILLE/ CHOCOLATE',
          src: require('@/assets/img/FLORIDA-EIS-VANILLE-CHOCOLATE-150ML.png'),
          amount: 0,
        },
        '0312': {
          title: 'COOKIE',
          src: require('@/assets/img/FLORIDA-EIS-COOKIE-150ML.png'),
          amount: 0,
        },
        '0332': {
          title: 'VANILLE/ ERDBEERE',
          src: require('@/assets/img/FLORIDA-EIS-VANILLE-ERDBEERE-150ML.png'),
          amount: 0,
        },
        '0340': {
          title: 'CHOCOLATE/ MANGO',
          src: require('@/assets/img/FLORIDA-EIS-CHOCOLATE-MANGO-150ML.png'),
          amount: 0,
        },

      },
      chartData: {}

    }
  },
  created() {
    //this.geoUrl();
    //this.fetchData();
    this.$watch(
        () => this.$route.params.id,
        () => {
          this.fetchData()
        },
        {immediate: true}
    )
    this.interval = setInterval(this.fetchData, 5000);
    this.initChart();
  },
  beforeUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  },
  methods: {
    fetchData() {
      const id = this.$route.params.id || this.defaultId;
      const url = '/api/freezer/' + id + '/inventory';
      api.get(url)
          .then(res => {
            console.log('res', res);
            //update recordId:
            this.recordId = res.id;
            //update articles and total amount
            var total = 0;
            const artNm = Object.keys(this.articles);
            artNm.forEach(an => {
              //console.log("article", an, res)
              const amount = res.articles[an] || 0;
              this.articles[an].amount = amount;
              total = total + amount;
            })
            this.total = total;
            //update temperature
            this.temp = res.temperature;
            //update gps
            //FIXME update GPS location
//            this.gps.lat = res.latitude;
//            this.gps.lng = res.longitude;
            this.geoUrl()
            //update date
            this.time = res.timestamp;
          })
          .catch(err => {
            console.log('err', err);
          });
    },
    geoUrl() {
      if(this.freezerId in FreezerUtil.data){
        this.osmUrl = GeoUtl.createOsmUrl(FreezerUtil.data[this.freezerId].gps);
      }else {
        this.osmUrl = null
      }
    },
    initChart() {
      const tempData = {
        "2021-07-07T09:29:17": -22.0,
        "2021-07-07T09:59:47": -22.3,
        "2021-07-07T10:22:55": -21.8,
        "2021-07-07T11:00:39": -26.1,
        "2021-07-07T11:31:09": -28.5,
        "2021-07-07T12:02:04": -29.1,
        "2021-07-07T12:02:44": -29.1,
        "2021-07-07T12:03:24": -29.1,
        "2021-07-07T12:04:04": -29.1,
        "2021-07-07T12:04:44": -29.2,
        "2021-07-07T12:05:24": -29.2,
        "2021-07-07T12:06:04": -29.2,
        "2021-07-07T12:06:44": -29.2,
        "2021-07-07T12:20:56": -29.3,
        "2021-07-07T12:21:56": -29.4,
        "2021-07-07T12:23:56": -29.5,
        "2021-07-07T12:24:56": -29.4,
        "2021-07-07T12:25:56": -29.5,
        "2021-07-07T12:26:56": -29.7,
        "2021-07-07T12:27:56": -29.6,
        "2021-07-07T12:29:56": -29.5
      };
      const timeLabels = Object.keys(tempData)
      const temperatures = Object.values(tempData);
      this.chartData = {
        labels: timeLabels,
        datasets: [{
          label: 'Temperaturverlauf',
          data: temperatures,
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }]
      };
      console.log("Home:initChart", this.chartData)
    }
  },
  computed: {
    freezerId: function () { return this.$route.params.id || this.defaultId },
    name: function () { return FreezerUtil.name(this.freezerId) },
    location: function () { return FreezerUtil.location(this.freezerId) },
    embed: function () {
      return this.$route.query.embed === 'true';
    }
  }

}
</script>

<style scoped lang="scss">

@import "../assets/styles/variables";
@import "~bootstrap";

.card {
  background-color: $light-blue;
  border: solid 8px $blue;
  box-shadow: none;
}

.small {
  font-size: 0.65em;
}

.text-xl {
  font-size: 3.75rem;
  line-height: 1;
  font-weight: bold;
}

.amount-wrapper {
  height: 3.75rem;
}

.product-title {
  @extend .h3;
  font-size: calc(1rem + 0.6vw);
}

@include media-breakpoint-up(xxl) {
  .text-xl {
    font-size: 4.5rem;
  }

  .amount-wrapper {
    height: 4.5rem;
  }
}

@include media-breakpoint-up(xxxl) {
  .text-xl {
    font-size: 6.5rem;
  }

  .amount-wrapper {
    height: 6.5rem;
  }
}

img.product-img {
  align-self: flex-start;
  -webkit-align-self: flex-start;
}


</style>
