export const GeoUtl =  {
    createOsmUrl: function(gps) {
        if (gps.lat === 0 && gps.lng === 0) {
            return null;
        }
        const url = new URL('https://www.openstreetmap.org/export/embed.html')
        const boxOffset = 0.0025
        url.search = new URLSearchParams({
            bbox: [gps.lng - boxOffset, gps.lat - boxOffset, gps.lng + boxOffset, gps.lat + boxOffset].join(','),
            layer: 'mapnik',
            marker: [gps.lat, gps.lng].join(',')
        });
        return url.toString();
    }
}