<template>
  <h3>Truhe: {{ $route.params.id }}</h3>
  <div v-if="loading" class="loading">Daten werden geladen</div>
  <div v-if="error" class="error">{{ error }}</div>
  <div v-if="page" class="content">
    <p>{{ page.totalElements }} Records gefunden.</p>
    <table class="table table-sm table-hover" v-if="page.totalElements > 0">
      <thead>
      <tr>
        <th>Record</th><th>Datum</th><th>Erfasste Tags</th><th>Temperatur</th><th>GPS</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="rec in page.content" :key="rec.id">
        <td><router-link :to="{name: 'Record', params: {id: rec.id}}">{{ rec.id }}</router-link></td>
        <td>{{ $fmt.dateAndTime(rec.timestamp) }}</td>
        <td>{{ rec.tags.length }}</td>
        <td>{{ $fmt.number(rec.temperature) }} °C</td>
        <td> {{ $fmt.gps(rec.latitude) }}°, {{ $fmt.gps(rec.longitude) }}°</td>
      </tr>
      </tbody>
    </table>
    <div class="nav-pager">
      <pager @select-page="fetchData" :page="page" />
    </div>
  </div>
</template>

<script>
import api from "@/api/ApiProvider";
import Pager from "@/components/Pager";

export default {
  name: "Freezer",
  data() {
    return {
      loading: false,
      page: null,
      error: null
    }
  },
  created() {
    this.$watch(
        () => this.$route.params.id,
        (id) => {
          if(id) this.fetchData(0)
        },
        {immediate: true}
    )
  },
  methods: {
    fetchData(page) {
      this.loading = true;
      const url = '/api/freezer/' + this.$route.params.id
      api.get(url, {page, sort: 'timestamp,desc'})
          .then(page => {
            console.log(page);
            this.loading = false;
            this.page = page;
            this.error = null;
          })
          .catch(err => {
            this.loading = false;
            this.page = null;
            this.error = err;
          })
    }
  },
  components: {
    Pager
  }
}
</script>

<style scoped>
</style>