import {createRouter, createWebHistory} from 'vue-router'
import FreezerList from "@/views/FreezerList";
import Freezer from "@/views/Freezer";
import Record from "@/views/Record";
import NotFound from "@/views/NotFound";
import Stock from "@/views/Stock";
import Chart from "@/views/Chart";
import Home from "@/views/Home";

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home/:id?',
        name: 'Home',
        component: Home
    },
    {
        path: '/stock/:id',
        name: 'Stock',
        component: Stock
    },
    {
      path: '/chart/:id',
      name: 'Chart',
      component: Chart
    },
    {
        path: '/freezer',
        name: 'FreezerList',
        component: FreezerList
    },
    {
        path: '/freezer/:id',
        name: 'Freezer',
        component: Freezer
    },
    {
        path: '/record/:id',
        name: 'Record',
        component: Record
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }, {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
